<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(show-more-info)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span title="View">
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'show-performance-evaluation',
                    params: { id: data.item.id ,
                              show : 'show-info'
                    }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'performance-evaluation/done',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        // { key: 'status', label: 'status' },
        { key: 'from', label: 'from' },
        { key: 'to', label: 'to' },
        { key: 'next_evaluation_date', label: 'Next eval.. Date' },
        { key: 'user.name', label: 'user name' },
        { key: 'show-more-info', label: 'Show Info' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = 'performance-evaluation/done?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

  <style></style>
